const router = new Cake.Router({

    '/':{
        name:'home',
        display:'Home',
        components:[],

        auth:false,
    },

    '404':function(){
        return '/';
    },
},{
    // auth:{
    //     '401':'/',
    //     verify:['api','verify'],
    //     valid:{
    //         superadmin:'user',
    //         admin:'transaction',
    //         merchant:'transaction',

    //     }
    // },
});